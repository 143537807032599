@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Segoe UI", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hideDetails .inactive {
  opacity: 0.5;
}

tr.disabled {
  background-color: grey;
}

.font-bold {
  font-weight: bold;
}

.pageItem {
  width: 40px;
  text-align: center;
}